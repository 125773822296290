import React from "react";
import HeroBannerVideo from "./HeroBanner/HeroBannerVideo";
// import CardContainer from "./Card/CardContainer";
// import Simulator from "./Simulator/Simulator";
// import Slideshow from "./Slideshow/Slideshow";
// import ContactForm from "./ContactForm/ContactForm";

const Home = () => {
  return (
    <div>
      <HeroBannerVideo
        subtitle="EL DE FIAR"
        title="DECIDE COMO MANEJAR TU DINERO"
        videoUrl="./videos/hero-banner.mp4"
      />
      {/* <CardContainer /> */}
      {/* <Simulator /> */}
      {/* <Slideshow />
      <ContactForm /> */}
    </div>
  );
};

export default Home;
